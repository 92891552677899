import tippy from 'tippy.js'
import 'tippy.js/themes/light.css'

const addTooltip = ($referenceElement) => {
    if ($referenceElement.dataset.htmlTooltip !== undefined) {
        tippy($referenceElement, {
            trigger: 'click',
            hideOnClick: true,
            placement: 'top',
            arrow: true,
            theme: $referenceElement.dataset.tooltipTheme ?? 'dark',
            content: $referenceElement.querySelector('.tooltip-content').outerHTML,
            allowHTML: true
        })
    } else {
        tippy($referenceElement, {
            trigger: 'click',
            hideOnClick: true,
            placement: 'top',
            arrow: true,
            theme: $referenceElement.dataset.tooltipTheme ?? 'dark'
        })
    }
}

export default addTooltip
