/**
 * Fix tippyjs issue where elements are
 * left in focus state when the browser tab is idle,
 * making every tooltip popup at the same time when going to the browser tab
 * */
const tippyFocus = () => {

    window.addEventListener('blur', () => {
        const { activeElement } = document
        if (activeElement.blur && activeElement.hasAttribute('data-decorate')) {
            activeElement.blur()
        }
    })
}
export default tippyFocus