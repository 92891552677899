import axios from "axios";

const getHiddenProduct = (config, $productList) => {
    const productTypeCount = {
        loans: $productList.querySelectorAll('.pe-product.pe-loan').length,
        creditcards: $productList.querySelectorAll('.pe-product.pe-creditcard').length,
        onlineBanks: $productList.querySelectorAll('.pe-product.pe-onlinebank').length,
        banners: $productList.querySelectorAll('.pe-product.pe-banner').length,
    }

    const data = new FormData
    data.append('action', 'pe_products')
    data.append('id', $productList.dataset.id)
    data.append('postID', window.themeLion.analytics.wp_page_id)
    data.append('offset', $productList.dataset.visibleProducts)
    data.append('loanOffset', productTypeCount.loans)
    data.append('creditcardOffset', productTypeCount.creditcards)
    data.append('onlineBankOffset', productTypeCount.onlineBanks)
    data.append('bannerOffset', productTypeCount.banners)

    const productList = axios.post(
        config.url,
        data
    ).then(response => {
        return response.data
    })

    return productList
}

export default getHiddenProduct