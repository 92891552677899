const ga4ViewItemList = async ($product) => {
    // Product data
    const { name, id, category, position, positionByCategory, listId, listName } = $product.dataset
    // Product list data
    const productList = $product.closest('.pe-product-list')
    let sameCategoryListMax = null
    let totalProductCount = null
    let filtered = productList.dataset.filtered
    let sorted = productList.dataset.sorted
    if (productList) {
        const productListData = productList.dataset
        const totalCategoryCount = `total${category.charAt(0).toUpperCase() + category.slice(1)}Count`
        sameCategoryListMax = productListData[totalCategoryCount]
        totalProductCount = productListData.totalProductCount
    }
    const event = {
        event: 'view_item_list',
        ecommerce: {
            items: [
                {
                    item_name: name,
                    item_id: id,
                    item_brand: name,
                    item_category: category,
                    item_list_name: listName,
                    item_list_id: listId,
                    index: position,
                    index_list_max: totalProductCount,
                    index_same_category: positionByCategory,
                    index_same_category_list_max: sameCategoryListMax,
                    list_is_sorted: sorted,
                    list_is_filtered: filtered,
                    quantity: '1'
                }
            ]
        }
    }
	
	if (navigator.userAgent.toLowerCase().includes('cookiebot') != true) {
    	window.dataLayer.push(event)
    }
}

export default ga4ViewItemList
