import addTooltip from './addTooltip'
import ajaxConfig from './ajaxConfig'
import decorateLink from './decorateLink'
import getProductList from './getProductList'
import { kebabToCamelCase, currencyFormat } from './helpers'
import listEventHandlers from './listEventHandlers'
import productEventHandlers from './productEventHandlers'
import currency from 'currency.js'

const filterOptions = async ($productList) => {
    if ($productList.dataset.filterOptions != 'true' || !document.querySelector('.pe-filter-options')) return

    // Vars
    let productListID = $productList.dataset.id
    let $products = $productList.querySelectorAll('.pe-product-list .pe-product-list__item .pe-product')
    let $productsContainer = $productList.querySelector('.pe-product-list__products')
    let $productSorting = $products

    const $productListOptions = document.querySelector(`.pe-filter-options[data-id="${productListID}"]`)
    const $originalProductList = $productList.cloneNode(true)
    const $productListPopupFilters = $productListOptions.querySelector('.pe-filter-options__filtering-popup')
    const $amountInputRange = $productListOptions.querySelector('input[name=amount-range][type=range]')
    const $durationInputRange = $productListOptions.querySelector('input[name=duration-range][type=range]')
    const currencySymbol = document.querySelector('input[name=currency-symbol]').value
    const currencyPosition = document.querySelector('input[name=currency-position]').value
    const thousandSeparator = document.querySelector('input[name=thousand-separator]').value
    const decimalSeparator = document.querySelector('input[name=decimal-separator]').value

    if (!$productListOptions) return

    const updateProductCount = () => {
        const $visibleProducts = $productList.querySelectorAll('.pe-product-list__item[data-matched="true"]')
        const $productCounter = $productListOptions.querySelector('.pe-filter-options__product-count span')
        if (!$productCounter) return

        $productCounter.innerHTML = $visibleProducts.length
    }

    const loadingAnimation = () => {
        $productList.classList.add('pe-product-list__loading')
        setTimeout(() => {
            $productList.classList.remove('pe-product-list__loading')
        }, 400)
    }

    const resetSliders = () => {
        if (!$amountInputRange || !$durationInputRange) return

        $amountInputRange.value = $amountInputRange.min
        $durationInputRange.value = $durationInputRange.min

        updateSliderBg($amountInputRange)
        updateSliderBg($durationInputRange)
    }

    const updateSliderBg = ($input) => {
        if (!$input) return

        if ($input.type == 'range') {
            const value = (($input.value - $input.min) / ($input.max - $input.min)) * 100
            const $inputNumber = $productListOptions.querySelector(
                `input[type="number"][name="${$input.name.replace('-range', '')}"]`
            )
            if ($input.value == $input.min) {
                $inputNumber.value = ''
            } else {
                $inputNumber.value = $input.value
            }
            $input.style.background =
                'linear-gradient(to right, var(--wp--custom--product-engine--product-filter--input--range--track--background) ' +
                value +
                '%, #ececec ' +
                value +
                '%, #ececec 100%)'
        } else {
            if ($productListOptions.querySelector(`input[type="range"][name="${$input.name}-range"]`)) {
                const $inputRange = $productListOptions.querySelector(
                    `input[type="range"][name="${$input.name}-range"]`
                )
                if ($input.value == '' || $input.value == $inputRange.min) {
                    $inputRange.value = $inputRange.min
                } else {
                    $inputRange.value = $input.value
                }
                const value = (($inputRange.value - $inputRange.min) / ($inputRange.max - $inputRange.min)) * 100

                $inputRange.style.background =
                    'linear-gradient(to right, var(--wp--custom--product-engine--product-filter--input--range--track--background) ' +
                    value +
                    '%, #ececec ' +
                    value +
                    '%, #ececec 100%)'
            }
        }
    }

    const updateSliderRanges = () => {
        if (!$amountInputRange || !$durationInputRange) return

        let amountMin = 0
        let amountMax = 0
        let durationMin = 0
        let durationMax = 0

        $products.forEach(($product) => {
            if (parseInt($product.dataset.amountMin) < amountMin || amountMin == 0) {
                amountMin = parseInt($product.dataset.amountMin)
            }
            if (parseInt($product.dataset.amountMax) > amountMax) {
                amountMax = parseInt($product.dataset.amountMax)
            }
            if (parseInt($product.dataset.durationMin) < durationMin || durationMin == 0) {
                durationMin = parseInt($product.dataset.durationMin)
            }
            if (parseInt($product.dataset.durationMax) > durationMax) {
                durationMax = parseInt($product.dataset.durationMax)
            }
        })

        if ($amountInputRange.value == $amountInputRange.min) {
            $amountInputRange.value = $amountInputRange.min
        }

        $amountInputRange.setAttribute('max', amountMax)
        if ($amountInputRange.value > amountMax) {
            $amountInputRange.value = amountMax
        }
        if ($amountInputRange.value < amountMin) {
            $amountInputRange.value = amountMin
        }

        if ($durationInputRange.value == $durationInputRange.min) {
            $durationInputRange.setAttribute('min', durationMin - 1)
            $durationInputRange.value = $durationInputRange.min
        }

        $durationInputRange.setAttribute('min', durationMin - 1)
        $durationInputRange.setAttribute('max', durationMax)

        if ($durationInputRange.value > durationMax) {
            $durationInputRange.value = durationMax
        }
        if ($durationInputRange.value < durationMin) {
            $durationInputRange.value = durationMin
        }

        if ($amountInputRange.parentElement.querySelector('.range')) {
            $amountInputRange.parentElement.querySelector('.range .max').innerHTML = currency(
                parseInt(amountMax),
                currencyFormat(currencySymbol, currencyPosition, thousandSeparator, decimalSeparator)
            ).format()

            $durationInputRange.parentElement.querySelector('.range .max').innerHTML = durationMax
        }

        const amountStep = () => {
            if ($amountInputRange.step == '') {
                if (amountMax >= 500000) {
                    return '10000'
                } else if (amountMax >= 100000) {
                    return '5000'
                } else if (amountMax >= 50000) {
                    return '1000'
                } else if (amountMax >= 10000) {
                    return '500'
                } else {
                    return '100'
                }
            } else {
                return $amountInputRange.step
            }
        }

        $amountInputRange.setAttribute('step', amountStep())

        updateSliderBg($amountInputRange)
        updateSliderBg($durationInputRange)
    }

    const updateProductPositions = () => {
        let position = 1
        let positionByCategoryLoan = 1
        let positionByCategoryCreditcard = 1
        let positionByCategoryOnlineBank = 1
        $productList.querySelectorAll('.pe-product').forEach(($product) => {
            $product.dataset.position = position
            if ($product.dataset.category == 'loan') {
                $product.dataset.positionByCategory = positionByCategoryLoan
                positionByCategoryLoan++
            }
            if ($product.dataset.category == 'creditcard') {
                $product.dataset.positionByCategory = positionByCategoryCreditcard
                positionByCategoryCreditcard++
            }
            if ($product.dataset.category == 'onlinebank') {
                $product.dataset.positionByCategory = positionByCategoryOnlineBank
                positionByCategoryOnlineBank++
            }
            position++
        })
    }

    const listChanger = async (renderList = false) => {
        let $newProductList
        if ($productListOptions.querySelector('[data-listid]')) {
            const $listChanger = $productListOptions.querySelector('[data-listid]')
            $productListOptions.querySelector('.pe-filter-options__extras').style.pointerEvents = 'none'
            $productList.classList.add('pe-product-list__loading')
            // Kill product notifications
            $productList.querySelectorAll('.pe-loan__button-primary').forEach(($button) => {
                $button._tippy ? $button._tippy.destroy() : ''
            })
            if ($listChanger.checked && $listChanger.dataset.listid != productListID) {
                productListID = $listChanger.dataset.listid
                // Get new product list
                const productList = await getProductList(ajaxConfig, productListID)

                if (productList.data) {
                    $newProductList = productList.data
                    // Convert string to HTML
                    $newProductList = new DOMParser().parseFromString($newProductList, 'text/html')
                    $newProductList = $newProductList.querySelector('.pe-product-list')
                    $newProductList.querySelectorAll('[data-src]').forEach(($img) => {
                        $img.setAttribute('src', $img.dataset.src)
                    })
                } else {
                    $newProductList = false
                }
            } else if (!$listChanger.checked && $listChanger.dataset.listid == productListID) {
                productListID = $originalProductList.dataset.id
                // Replace data-scr with scr
                $originalProductList.querySelectorAll('[data-src]').forEach(($img) => {
                    $img.setAttribute('src', $img.dataset.src)
                })
                // Replace the current list with the original one
                $newProductList = $originalProductList
            } else {
                $productListOptions.querySelector('.pe-filter-options__extras').style.pointerEvents = 'auto'
                return false
            }

            if (!$newProductList) {
            } else {
                // Set productlist variable
                $newProductList.querySelector('.pe-product-list__products').style.display = 'none'

                $productList.outerHTML = $newProductList.outerHTML

                $products = $productList.querySelectorAll('.pe-product')

                // Loading
                loadingAnimation()

                // List eventlisteners
                listEventHandlers($productList)
                // Decorate links
                $productList.querySelectorAll('[data-decorate]').forEach((element) => {
                    decorateLink(element)
                })
                // Add tooltips
                $productList.querySelectorAll('[data-tooltip]').forEach((element) => {
                    addTooltip(element)
                })
            }
        }
        if ($productListOptions.querySelector('.pe-filter-options__extras')) {
            $productListOptions.querySelector('.pe-filter-options__extras').style.pointerEvents = 'auto'
        }
        return $newProductList
    }

    const sortProducts = () => {
        // Vars
        const $sortingWrapper = $productListOptions.querySelector('.pe-filter-options__sorting-popup')
        if (!$sortingWrapper) return
        const $sortingInput = $sortingWrapper.querySelector('option:checked, input:checked')
        const $products = $productList.querySelectorAll('.pe-loan.pe-product')
        const $productsContainer = $productList.querySelector('.pe-product-list__products')
        const $productsArray = [...$products]

        loadingAnimation()

        $productsContainer.style.display = 'none'
        $productsContainer.innerHTML = ''

        if ($sortingInput.value == 'recommended') {
            $productList.dataset.sorted = 'false'
            $productsArray.sort((a, b) => {
                return a.dataset.originalPosition - b.dataset.originalPosition
            })
        } else {
            $productList.dataset.sorted = 'true'
            const sortOptions = {
                productAttribute: $sortingInput.dataset.productAttribute,
                direction: $sortingInput.dataset.sortingDirection
            }

            $productsArray.sort((a, b) => {
                const aVal = +a.dataset[kebabToCamelCase(sortOptions.productAttribute)]
                const bVal = +b.dataset[kebabToCamelCase(sortOptions.productAttribute)]

                if (sortOptions.direction === 'asc') {
                    return aVal - bVal
                }
                if (sortOptions.direction === 'desc') {
                    return bVal - aVal
                }
            })
        }

        $productsArray.forEach(($product) => {
            let $productClone = $product.parentElement
                ? $product.parentElement.cloneNode(true)
                : $product.cloneNode(true)
            $productClone.querySelectorAll('[data-src]')?.forEach(($img) => ($img.src = $img.dataset.src))
            $productClone.querySelectorAll('[data-srcset]')?.forEach(($img) => ($img.srcset = $img.dataset.srcset))

            $productsContainer.appendChild($productClone)
        })

        updateProductPositions()
        productEventHandlers($productList.querySelectorAll('.pe-loan.pe-product'))
        $productList.querySelectorAll('[data-tooltip]').forEach((element) => {
            addTooltip(element)
        })
        $productsContainer.style.display = 'block'
    }

    const filterProducts = async () => {
        const listChanged = await listChanger()
        // Vars
        let filterOptions = []
        let clonedProducts = []
        $productList = document.querySelector('.pe-product-list')
        $productsContainer = $productList.querySelector('.pe-product-list__products')
        $products = $productList.querySelectorAll('.pe-product-list__item .pe-product')
        const $inputs = $productListPopupFilters.querySelectorAll('input')
        const $extraInputs = $productListOptions.querySelectorAll(
            '.pe-filter-options__extras input:not([name=refinance])'
        )
        $productsContainer.style.display = 'none'
        $productList.dataset.filtered = 'true'

        loadingAnimation()

        if ($inputs) {
            filterOptions['inputs'] = {}
            ;[...$inputs].map(($input) => {
                filterOptions['inputs'][kebabToCamelCase($input.name)] = $input.value
            })
        }
        if ($extraInputs) {
            filterOptions['extraInputs'] = {}
            ;[...$extraInputs]
                .filter(($input) => {
                    return $input.checked
                })
                .map(($input) => {
                    filterOptions['extraInputs'][kebabToCamelCase($input.name)] = $input.checked ? '1' : 'false'
                })
        }
        const $allInputs = $extraInputs ? [...$inputs, ...$extraInputs] : [...$inputs]
        // Create and update product cards
        if ($productListOptions.querySelector('.pe-filter-options__cards')) {
            $allInputs.forEach(($input) => {
                if ($input.type == 'range') {
                    return
                }

                let filterValue = 0
                let filterName = ''
                if (!$productListOptions.querySelector(`.pe-filter-options__card[data-filter-name="${$input.name}"]`)) {
                    const $filterCard = document.createElement('div')
                    $filterCard.classList.add('pe-filter-options__card')
                    $filterCard.dataset.filterName = $input.name
                    if ($input.type == 'checkbox') {
                        filterValue = $input.checked ? '1' : ''
                    } else {
                        filterValue = $input.value
                    }
                    if ($input.name == 'amount' && filterValue) {
                        filterValue = currency(
                            filterValue,
                            currencyFormat(currencySymbol, currencyPosition, thousandSeparator, decimalSeparator)
                        ).format()
                    }
                    if ($input.name == 'duration' && filterValue) {
                        filterValue = `${filterValue} ${$input.dataset.filterLabelSuffix}`
                    }
                    if ($input.dataset.filterLabel) {
                        filterName = $input.dataset.filterLabel
                    } else {
                        filterName = ''
                    }
                    if (filterValue) {
                        if ($input.type == 'checkbox') {
                            filterValue = ''
                        }
                        $filterCard.innerHTML = `
                        <span class="pe-filter-options__card__name">${filterName}</span>
                        <span class="pe-filter-options__card__value">${filterValue}</span>
                        <span class="pe-filter-options__card__remove">x</span>
                        `
                        $productListOptions.querySelector('.pe-filter-options__cards').appendChild($filterCard)
                    }
                } else {
                    if ($input.type == 'checkbox') {
                        filterValue = $input.checked ? '1' : ''
                    } else {
                        filterValue = $input.value
                    }
                    if ($input.name == 'amount' && filterValue) {
                        filterValue = currency(
                            filterValue,
                            currencyFormat(currencySymbol, currencyPosition, thousandSeparator, decimalSeparator)
                        ).format()
                    }
                    if ($input.name == 'duration' && filterValue) {
                        filterValue = `${filterValue} ${$input.dataset.filterLabelSuffix}`
                    }
                    if (filterValue) {
                        if ($input.type == 'checkbox') {
                            filterValue = ''
                        }
                        $productListOptions.querySelector(
                            `.pe-filter-options__card[data-filter-name="${$input.name}"] .pe-filter-options__card__value`
                        ).innerHTML = filterValue
                    } else {
                        $productListOptions
                            .querySelector(`.pe-filter-options__card[data-filter-name="${$input.name}"]`)
                            .remove()
                    }
                }
            })
        }
        // Filtering cards event listeners
        $productListOptions.querySelectorAll('.pe-filter-options__card__remove').forEach(($removeButton) => {
            $removeButton.addEventListener('click', () => {
                const $card = $removeButton.parentElement
                const $input = $productListPopupFilters.querySelector(`input[name="${$card.dataset.filterName}"]`)
                if ($input.type == 'checkbox') {
                    $input.checked = false
                } else {
                    $input.value = ''
                    if ($input.name == 'amount') {
                        $amountInputRange.value = $amountInputRange.min
                        updateSliderBg($amountInputRange)
                    }
                    if ($input.name == 'duration') {
                        $durationInputRange.value = $durationInputRange.min
                        updateSliderBg($durationInputRange)
                    }
                }
                $card.remove()
                filterProducts()
            })
        })
        $products.forEach(($product) => {
            let matchedProduct = true
            let matchedProductAmount = true
            let matchedProductDuration = true
            let creditCardFiltering = false
            let creditCardMatch = null
            Object.keys(filterOptions['inputs']).forEach((key) => {
                if (
                    (key == 'amount' &&
                        parseInt(filterOptions['inputs'][key]) > parseInt($product.dataset.amountMax)) ||
                    (key == 'amount' && parseInt(filterOptions['inputs'][key]) < parseInt($product.dataset.amountMin))
                ) {
                    matchedProduct = false
                    matchedProductAmount = false
                }
                if (
                    (key == 'duration' &&
                        parseInt(filterOptions['inputs'][key]) > parseInt($product.dataset.durationMax)) ||
                    (key == 'duration' &&
                        parseInt(filterOptions['inputs'][key]) < parseInt($product.dataset.durationMin))
                ) {
                    matchedProduct = false
                    matchedProductDuration = false
                }
            })
            Object.keys(filterOptions['extraInputs']).forEach((key) => {
                if (key.startsWith('card_type')) {
                    creditCardFiltering = true
                    if (creditCardMatch == null && $product.dataset[key] == '1') {
                        creditCardMatch = true
                    }
                } else if ($product.dataset[key] != '1' && $product.dataset[key] != key) {
                    matchedProduct = false
                }
            })

            if (creditCardFiltering && !creditCardMatch) {
                matchedProduct = false
            }

            matchedProduct
                ? ($product.parentNode.dataset.matched = 'true')
                : ($product.parentNode.dataset.matched = 'false')
            matchedProductAmount
                ? ($product.parentNode.dataset.matchedAmount = 'true')
                : ($product.parentNode.dataset.matchedAmount = 'false')
            matchedProductDuration
                ? ($product.parentNode.dataset.matchedDuration = 'true')
                : ($product.parentNode.dataset.matchedDuration = 'false')
            let $productClone = $product.parentElement.cloneNode(true)
            $productClone.querySelectorAll('[data-src]').forEach(($img) => ($img.src = $img.dataset.src))
            $productClone.querySelectorAll('[data-srcset]').forEach(($img) => ($img.srcset = $img.dataset.srcset))
            clonedProducts.push($productClone)
        })
        // If all inputs are empty or value is equal to min value
        if (
            $allInputs.filter(($input) => {
                if ($input.type == 'checkbox') {
                    return $input.checked
                } else if ($input.type == 'range') {
                    return false
                } else {
                    return $input.value
                }
            }).length == 0
        ) {
            $productList.dataset.filtered = 'false'
        }

        $productsContainer.innerHTML = ''
        clonedProducts.forEach(($product) => {
            $productsContainer.appendChild($product)
        })

        sortProducts()

        if (listChanged) {
            updateSliderRanges()
            $products = $productList.querySelectorAll('.pe-loan.pe-product')
            $productSorting = $productList.querySelector('.pe-loan.pe-product')
        }

        $productList.querySelectorAll('[data-tooltip]').forEach((element) => {
            addTooltip(element)
        })

        $productsContainer.style.display = 'block'
        updateProductCount()
    }

    // Popup filters
    if ($productListOptions.querySelector('.pe-filter-options__filtering-popup-toggle')) {
        const $filteringPopupToggle = $productListOptions.querySelector('.pe-filter-options__filtering-popup-toggle')
        $filteringPopupToggle.addEventListener('click', (e) => {
            e.preventDefault()
            $productListOptions.classList.toggle('filters-open')
            $productListOptions.classList.remove('sorting-open')
        })
        if ($productListOptions.querySelector('.pe-filter-options__filtering-popup')) {
            // Default fields
            const $productListPopupFilters = $productListOptions.querySelector('.pe-filter-options__filtering-popup')
            const $productListPopupFilterButtons = $productListPopupFilters.querySelector(
                '.pe-filter-options__filtering-popup__buttons'
            )
            const $refinanceInput = $productListOptions.querySelectorAll('input[name=refinance]')
            let $inputs = $productListPopupFilters.querySelectorAll('input')
            const $extraInputs = $productListOptions.querySelectorAll(
                '.pe-filter-options__extras input:not([name=refinance])'
            )
            if ($extraInputs) {
                $inputs = [...$inputs, ...$extraInputs]
            }

            $inputs.forEach(($input) => {
                $input.addEventListener('input', (e) => {
                    updateSliderBg($input)
                })
            })

            updateSliderRanges()

            if ($amountInputRange && $durationInputRange) {
                $amountInputRange.value = $amountInputRange.min
                $durationInputRange.value = $durationInputRange.min

                updateSliderBg($amountInputRange)
                updateSliderBg($durationInputRange)
            }

            // Close popup
            $productListPopupFilters
                .querySelector('.pe-filter-options__filtering-popup__close')
                .addEventListener('click', () => {
                    $productListOptions.classList.remove('filters-open')
                })

            // Buttons
            if ($productListOptions.querySelector('.pe-filter-options__filtering-popup__button--reset')) {
                $productListOptions
                    .querySelector('.pe-filter-options__filtering-popup__button--reset')
                    .addEventListener('click', async (e) => {
                        e.preventDefault()
                        $productList.filtered = 'false'
                        $inputs.forEach(($input) => {
                            if ($input.type == 'checkbox') {
                                $input.checked = false
                            } else if ($input.type == 'range') {
                                // Do nothing
                            } else {
                                $input.value = ''
                            }
                        })

                        $productListOptions.querySelectorAll('.pe-filter-options__card').forEach(($card) => {
                            $card.remove()
                        })

                        if ($refinanceInput) {
                            $refinanceInput.forEach(($input) => {
                                $input.checked = false
                                $input.dispatchEvent(new Event('input'))
                            })
                        }
                        $productListOptions.classList.remove('filters-open')
                        await filterProducts()
                        updateSliderRanges()
                        resetSliders()
                        $productList.dataset.filtered = 'false'
                    })
            }
            $productListOptions
                .querySelector('.pe-filter-options__filtering-popup__button--apply')
                .addEventListener('click', (e) => {
                    e.preventDefault()
                    loadingAnimation()
                    $productListOptions.classList.remove('filters-open')
                    filterProducts()
                })
        }
    }
    if ($productListOptions.querySelector('.pe-filter-options__sorting-popup-toggle')) {
        const $sortingPopupToggle = $productListOptions.querySelector('.pe-filter-options__sorting-popup-toggle')
        $sortingPopupToggle.addEventListener('click', (e) => {
            e.preventDefault()
            $productListOptions.classList.toggle('sorting-open')
            $productListOptions.classList.remove('filters-open')
        })
        if ($productListOptions.querySelector('.pe-filter-options__sorting-popup')) {
            const $sortingWrapper = $productListOptions.querySelector('.pe-filter-options__sorting-wrapper')
            $sortingWrapper.querySelectorAll('select, input').forEach(($input) => {
                $input.addEventListener('change', (e) => {
                    sortProducts()

                    // Close popup
                    $productListOptions.classList.remove('sorting-open')

                    // Set active class on toggle
                    $input.parentElement.classList.add('active')
                    $sortingWrapper.querySelectorAll('label').forEach(($item) => {
                        if ($item != $input.parentElement) {
                            $item.classList.remove('active')
                        }
                    })
                })
            })
        }
    }
}

export default filterOptions
