export const kebabToCamelCase = (string) => {
    return string.replace(/-./g, (x) => x.toUpperCase()[1])
}

export const throttle = (callback, limit) => {
    var waiting = false
    return function () {
        if (!waiting) {
            callback.apply(this, arguments)
            waiting = true
            setTimeout(function () {
                waiting = false
            }, limit)
        }
    }
}

export const preventBackgroundScroll = (active = true) => {
    if (window.innerWidth < 768) {
        if (active) {
            document.body.style.top = `-${window.scrollY}px`
            document.body.style.position = 'fixed'
        } else {
            const scrollY = document.body.style.top
            document.body.style.position = ''
            document.body.style.top = ''
            window.scrollTo(0, parseInt(scrollY || '0') * -1)
        }
    }
}

export const currencyFormat = (
    currencySymbol,
    currencyPosition = 'after',
    thousandSeparator = '.',
    decimalSeparator = ','
) => {
    return {
        separator: thousandSeparator,
        decimal: decimalSeparator,
        symbol: ` ${currencySymbol}`,
        precision: 0,
        pattern: currencyPosition === 'before' ? '!#' : '#!',
        negativePattern: currencyPosition === 'before' ? '-!#' : '-#!'
    }
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}
