import axios from "axios";

const getProductList = (config, id) => {
    const data = new FormData
    data.append('action', 'pe_product_list')
    data.append('id', id)
    data.append('postID', window.themeLion.analytics.wp_page_id)

    const productList = axios.post(
        config.url,
        data
    ).then(response => {
        return response.data
    })

    return productList
}

export default getProductList