import { preventBackgroundScroll } from './utils/helpers'
import MicroModal from 'micromodal'

const product = async ($products) => {
    $products.forEach(($product) => {
        // Product score
        $product.querySelector('.pe-loan__user-rating').addEventListener('click', (e) => {
            const $productScoring = $product.querySelector('.pe-product__scoring')
            if ($productScoring.classList.contains('hidden')) {
                $productScoring.classList.remove('hidden')
                preventBackgroundScroll()
            } else {
                $productScoring.classList.add('hidden')
                preventBackgroundScroll(false)
            }
        })
        $product.querySelectorAll('[data-action=close]').forEach(($closeButton) => {
            $closeButton.addEventListener('click', (e) => {
                preventBackgroundScroll(false)
                $product.querySelector('.pe-product__scoring').classList.add('hidden')
            })
        })
        // Trustpilot modal
        $product.querySelector('.pe-product__scoring__trustpilot')?.addEventListener('click', (e) => {
            const modal = document.querySelector('#trustpilot-modal')
            if (!modal) return
            const productData = $product?.querySelector('.pe-product')?.dataset
            MicroModal.show('trustpilot-modal')
            fetch(
                `${window.lsProductEngine.wp_site_url}/wp-json/product-engine/v1/trustpilot-reviews-block/?id=${productData.brandId}&count=3`,
                {
                    method: 'GET',
                    cache: 'no-cache',
                    credentials: 'same-origin'
                }
            )
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    modal.querySelector('#trustpilot-modal-content').innerHTML = response
                })
        })
    })
}

export default product
