import decorateLink from './utils/decorateLink'
import addTooltip from './utils/addTooltip'
import productList from './productList'
import product from './product'
import toList from './toList'

import tippyFocus from './fixes/tippyjs'

// Set empty dataLayer if it does not exist
window.dataLayer = window.dataLayer || []
document.addEventListener('DOMContentLoaded', () => {
    // Vars
    const $productLists = document.querySelectorAll('.pe-product-list')

    // Product lists
    document.querySelectorAll('.pe-product-list').forEach(($productList) => {
        productList($productList)
    })

    // To list handler
    toList($productLists)

    // Single product blocks
    const $products = document.querySelectorAll('.wp-block-ls-product-block')
    product($products)
})

// Decorate links
if (navigator.userAgent.toLowerCase().includes('cookiebot') != true) {
	document.querySelectorAll('[data-decorate]').forEach((element) => {
	    decorateLink(element)
	})
}

// Add tooltips
document.querySelectorAll('[data-tooltip]').forEach((element) => {
    addTooltip(element)
})

// Fixes
tippyFocus()
