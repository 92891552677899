import ga4ViewItem from '../analytics/events/ga4-view-item'
import ga4ViewItemList from '../analytics/events/ga4-view-item-list'

export const productViewObserver = ($product, action = 'observe') => {
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    }

    const callback = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting === true) {
                ga4ViewItem($product)
                ga4ViewItemList($product)

                observer.unobserve(entry.target)
            }
        }
    }

    const productObserver = new IntersectionObserver(callback, options)

    if (action === 'observe') {
        productObserver.observe($product)
    }

    if (action == 'disconnect') {
        productObserver.disconnect()
    }
}
