import ga4AddToCart from '../analytics/events/ga4-add-to-cart'
import ga4SelectItem from '../analytics/events/ga4-select-item'
import { productViewObserver } from './productObservers'
import { preventBackgroundScroll } from './helpers'
import MicroModal from 'micromodal'

import ajaxConfig from './ajaxConfig'

const productEventHandlers = async ($products) => {
    MicroModal.init({
        disableScroll: true
    })

    $products.forEach(($product) => {
        /* Variables */
        const productType = $product.dataset.category
        const productID = $product.dataset.id

        // Impressions
        productViewObserver($product)
        // Link clicks
        $product.querySelectorAll('[data-js-hook="pick-product"]').forEach(($productLink) => {
            $productLink.addEventListener('click', (e) => {
                ga4AddToCart($product, e.target)
                ga4SelectItem($product, e.target)
            })
        })

        // Trustpilot modal
        $product.querySelector(`.pe-product__scoring__trustpilot`)?.addEventListener('click', (e) => {
            const modal = document.querySelector('#trustpilot-modal')
            if (!modal) return
            MicroModal.show('trustpilot-modal')
            fetch(
                `${window.lsProductEngine.wp_site_url}/wp-json/product-engine/v1/trustpilot-reviews-block/?id=${$product.dataset.brandId}&count=3`,
                {
                    method: 'GET',
                    cache: 'no-cache',
                    credentials: 'same-origin'
                }
            )
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    modal.querySelector('#trustpilot-modal-content').innerHTML = response
                })
        })

        // Product score
        $product.querySelector(`.pe-${productType}__user-rating`)?.addEventListener('click', (e) => {
            const $productScoring = $product.querySelector(`.pe-product__scoring`)
            if (!$productScoring) return
            if ($productScoring.classList.contains('hidden')) {
                $product.querySelector(`.pe-${productType}__user-rating`).classList.add('active')
                $productScoring.classList.remove('hidden')
                preventBackgroundScroll()
            } else {
                $product.querySelector(`.pe-${productType}__user-rating`).classList.remove('active')
                $productScoring.classList.add('hidden')
                preventBackgroundScroll(false)
            }
        })
        $product.querySelectorAll('[data-action=close]').forEach(($closeButton) => {
            $closeButton.addEventListener('click', (e) => {
                preventBackgroundScroll(false)
                $product.querySelector(`.pe-${productType}__user-rating`).classList.remove('active')
                $product.querySelector(`.pe-product__scoring`).classList.add('hidden')
            })
        })
    })
}

export default productEventHandlers
