const decorateLink = ($element) => {
    const $product = $element.closest('.pe-product')
    let href = $element.href
    if (!$product || !href) return
    const listId = $product.dataset.listId
    const pageId = $product.dataset.pageId
    const position = $product.dataset.position
    const label = $element.dataset.label

    const urlParameterWhiteList = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term',
        'transaction_id',
        'at_gd',
        'asclid',
        'gclid',
        'msclkid',
        'fbclid',
        't'
    ]

    // Add product data to url
    href = `${href}?listid=${listId}&pos=${position}&pageid=${pageId}&label=${label}`

    // Add url parameters to url
    const urlParams = new URLSearchParams(window.location.search)
    urlParameterWhiteList.forEach((param) => {
        if (urlParams.has(param)) {
            href = `${href}&${param}=${urlParams.get(param)}`
        }
    })

	// Add google/organic or bing/organic parameters to url if referrer is Google or Bing and source/medium is not set
    const httpReferrer = document.referrer;
    if (
    	httpReferrer.indexOf('google.') > -1 && 
    	urlParams.has('utm_source') == false && 
    	urlParams.has('utm_medium') == false
    ) {
	    href = `${href}&utm_source=google&utm_medium=organic`
    } else if(
    	httpReferrer.indexOf('bing.') > -1 && 
    	urlParams.has('utm_source') == false && 
    	urlParams.has('utm_medium') == false
    ) {
	    href = `${href}&utm_source=bing&utm_medium=organic`
    }

    $element.setAttribute('href', href)
}

export default decorateLink
