const toList = ($productLists) => {
    // Vars
    const $toListButton = document.querySelector('#pe-to-list')

    if (!$toListButton) return

    // Eventlisteners
    $toListButton.addEventListener('click', () => {
        document.querySelector('#list').scrollIntoView({ behavior: 'smooth' })
    })

    // To list observer
    let observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    }
    const toListObserverCallback = (entries, observer) => {
        const $toList = document.querySelector('#pe-to-list')
        for (const entry of entries) {
            if (entry.isIntersecting === true || window.scrollY < 2000) {
                $toList.style.display = 'none'
                return
            }

            $toList.style.display = 'block'
        }
    }
    let observer = new IntersectionObserver(toListObserverCallback, observerOptions)
    $productLists.forEach(($productList) => {
        observer.observe($productList)
    })
}

export default toList
