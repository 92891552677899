import listEventHandlers from './utils/listEventHandlers'
import ajaxConfig from './utils/ajaxConfig'
import filterOptions from './utils/filterOptions'
import getProductList from './utils/getProductList'
import productEventHandlers from './utils/productEventHandlers'
import decorateLink from './utils/decorateLink'
import addTooltip from './utils/addTooltip'

const ProductList = async ($productList) => {
    // List event handlers
    if ($productList.dataset.initialAjaxLoad === 'true') {
        const productList = await getProductList(ajaxConfig, $productList.dataset.id)
        $productList.querySelector('.pe-product-list__products').innerHTML = productList.data
        // Add eventlisteners
        listEventHandlers($productList)
        // Decorate links
        $productList.querySelectorAll('[data-decorate]').forEach((element) => {
            decorateLink(element)
        })
        // Add tooltips
        $productList.querySelectorAll('[data-tooltip]').forEach((element) => {
            addTooltip(element)
        })
    } else {
        listEventHandlers($productList)
    }
    // Filter options
    if ($productList.dataset.filterOptions === 'true') {
        filterOptions($productList)
    }
    // List product eventhandlers
    const $products = $productList.querySelectorAll('.pe-product')
    productEventHandlers($products)
}

export default ProductList
