import tippy from 'tippy.js';

const productNotification = (element,delay) => {
    tippy(element, {
        trigger: 'manual',
        theme: 'themelion',
        zIndex: 10,
        hideOnClick: false,
        placement: "top",
        arrow: true,
        content: `${window.lsProductEngine.locale.loanNofiticationText}`,
        onShow(instance) {
            setTimeout(() => {
                instance.hide();
            }, 3000);
        },
        onHide(instance) {
            let randomDelay = (Math.random() * (15 - 8 + 1) + delay) * 1000
            setTimeout(() => {
                instance.show();
            }, randomDelay);
        },
        onCreate(instance) {
            setTimeout(() => {
                instance.show();
            }, (delay*1000) + Math.random() * (5 - 1 + 1) * 1000);
        }
    })
}

export default productNotification;
