import getHiddenProducts from './getHiddenProducts'
import ajaxConfig from './ajaxConfig'
import productEventHandlers from './productEventHandlers'
import productNotification from './productNotification'

const listEventHandlers = async ($productList) => {
    if ($productList.dataset.productNotification == 'true') {
        // Vars
        let notificationDelay = 5
        const $products = $productList.querySelectorAll('.pe-product')

        $products.forEach(($product) => {
            $product.querySelectorAll('.pe-loan__button-primary').forEach((element) => {
                productNotification(element, notificationDelay)
                notificationDelay += 15
            })
        })
    }
    /* Show all products button */
    const $showAllProductsButton = $productList.querySelector('.pe-show-all-products')
    if ($showAllProductsButton) {
        $showAllProductsButton.addEventListener('click', async (e) => {
            e.target.classList.add('pe-spinner')
            const hiddenProducts = await getHiddenProducts(ajaxConfig, $productList)
            $productList
                .querySelector('.pe-product-list__products')
                .insertAdjacentHTML('beforeend', hiddenProducts.data)

            const $allProducts = $productList.querySelectorAll('.pe-product')
            const $newProducts = [...$allProducts].slice(hiddenProducts.offset)

            productEventHandlers($newProducts)
            $newProducts.forEach(($product) => {
                const $primaryButton = $product.querySelector('.pe-loan__button-primary')
                if ($productList.dataset.productNotification == 'true') {
                    productNotification($primaryButton, notificationDelay)
                    notificationDelay += 15
                }
            })

            $productList.querySelector('.pe-product-list__show-all').remove()
        })
    }
}

export default listEventHandlers
